<template>
    <div class="split-two margin-bottom-15 button-wrapper">
        <Button
            v-if="isCommissionOpen && $hasPermission('updateClose')"
            buttonWord="資料暫停更新， 取得佣金報表"
            buttonStyle="blue"
            :disabled="!isPksSyncAvailable || !isGenerateAvailable"
            @click="showClosingAlert()"
            class="step-button"
        />
        <Button
            v-if="isCommissionClosed && $hasPermission('updateOpen')"
            buttonWord="PKS資料有異動， 返回每日更新階段"
            buttonStyle="blue-hollow"
            @click="showOpeningAlert()"
            class="step-button"
        />
        <Button
            v-if="isCommissionClosed && $hasPermission('visibleSales')"
            buttonWord="開放業務員查詢"
            buttonStyle="blue"
            @click="showSetVisibleAlert()"
            class="step-button"
        />
        <Button
            v-if="isCommissionVisible && $hasPermission('invisibleSales')"
            buttonWord="關閉業務員查詢"
            buttonStyle="blue-hollow"
            @click="showSetVisibleAlert()"
            class="step-button"
        />
    </div>
    <ClosingAlert
        :mType="mType"
        v-model="isShowClosingAlert"
        v-model:success="isShowSuccessModal"
        v-model:commissionStatus="commissionStatusValue"
    />
    <OpeningAlert
        :mType="mType"
        v-model="isShowOpeningAlert"
        v-model:success="isShowSuccessModal"
        v-model:commissionStatus="commissionStatusValue"
    />
    <AdminSetVisibleAlert
        :mType="mType"
        :isVisible="!isCommissionVisible"
        v-model="isShowSetVisibleAlert"
        v-model:commissionStatus="commissionStatusValue"
        v-model:success="isShowSuccessModal"
    />
    <SuccessModal v-model="isShowSuccessModal" message="已完成" />
    <ErrorModal v-model="isShowErrorModal" message="系統錯誤" />
</template>

<script>
import Button from '@/components/Button.vue'
import AdminSetVisibleAlert from '@/containers/commission/AdminSetVisibleAlert.vue'
import SuccessModal from '@/containers/SuccessModal.vue'
import ErrorModal from '@/containers/ErrorModal.vue'
import ClosingAlert from '@/containers/commission/AdminClosingAlert.vue'
import OpeningAlert from '@/containers/commission/AdminOpeningAlert.vue'

export default {
    name: 'AdminController',
    components: {
        Button,
        SuccessModal,
        ErrorModal,
        ClosingAlert,
        OpeningAlert,
        AdminSetVisibleAlert
    },
    props: {
        mType: {
            type: String
        },
        commissionStatus: {
            type: String
        },
        isPksSyncAvailable: {
            type: Boolean
        },
        isGenerateAvailable: {
            type: Boolean
        },
        isCommissionOpen: {
            type: Boolean
        },
        isCommissionClosed: {
            type: Boolean
        },
        isCommissionVisible: {
            type: Boolean
        }
    },
    methods: {
        showClosingAlert: function () {
            if (!this.$hasPermission('updateClose')) {
                this.$showErrorMessage(
                    '此帳號尚無資料更新的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            this.$setGaEvent('showClosingAlert', 'click-Button')
            this.isShowClosingAlert = true
        },
        showOpeningAlert: function () {
            if (!this.$hasPermission('updateOpen')) {
                this.$showErrorMessage(
                    '此帳號尚無資料更新的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            this.$setGaEvent('showOpeningAlert', 'click-Button')
            this.isShowOpeningAlert = true
        },
        showSetVisibleAlert: function () {
            if (
                !this.$hasPermission('visibleSales') ||
                !this.$hasPermission('invisibleSales')
            ) {
                this.$showErrorMessage(
                    '此帳號尚無開放/關閉業務員查詢的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }

            this.$setGaEvent(
                this.isCommissionVisible
                    ? 'showVisibleAlertFalse'
                    : 'showVisibleAlertTrue',
                'click-Button'
            )
            this.isShowSetVisibleAlert = true
        }
    },
    computed: {
        commissionStatusValue: {
            get() {
                return this.commissionStatus
            },
            set(val) {
                this.$emit('update:commissionStatus', val)
            }
        }
    },
    data() {
        return {
            isShowSuccessModal: false,
            isShowErrorModal: false,
            isShowClosingAlert: false,
            isShowOpeningAlert: false,
            isShowSetVisibleAlert: false
        }
    }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
    :deep(.blue-hollow-button:first-child) {
        margin: 0 auto 0 1px;
    }

    @media screen and (max-width: 576px) {
        :deep(.blue-hollow-button:first-child) {
            margin: 0 auto 0 0px;
        }
        button {
            width: 45%;
            margin: 0 0 15px 0;
        }
        button:last-child {
            margin: 0 0 0 0;
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;
}
</style>
